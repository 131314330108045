import React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import current729Logo from '../images/729solutions_logo.png'
import Logo2003 from '../images/logos/2003.logo.png'
import Logo2014One from '../images/logos/2014.logo.one.png'
import Logo2014Two from '../images/logos/2014.logo.two.png'
import Timeline from '../components/timeline'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const CompanyHistory = ({ location }) => {
  const companyTimeline = [
    {
      id: 1,
      year: '2003',
      content:
        'Founded in 2003, 729 Solutions has grown from their start as a software development shop, to a prominent full-service technology consulting agency. \n\nThe founders first met in the digital agency world at the end of the 1990s, and knew that they wanted to create a new model for technology consulting and implementation work. They survived the bursting tech bubble of the early 2000s and launched 729 Solutions in 2003.',
      logoOne: {
        src: Logo2003,
        alt: '729 Logo from 2003',
      },
      logoTwo: {
        src: '',
        alt: '',
      },
    },
    {
      id: 2,
      year: '2014',
      content:
        'Their vision for a new world of technology consulting relied on finding exceptional people with rich skill sets who could grow the business overtime. What started as a boutique software development shop has now become a multi-faceted and international technology consulting agency, with hundreds of successful projects under their belt.',
      logoOne: {
        src: Logo2014One,
        alt: '729 Logo One from 2014',
      },
      logoTwo: {
        src: Logo2014Two,
        alt: '729 Logo Two from 2014',
      },
    },
    {
      id: 3,
      year: 'Today',
      content:
        'Today, 729 Solutions has an international team who works on projects of all sizes across a variety of platforms and services. Their projects have rock-solid backends that are secure and stable, and eye-popping front ends with amazing designs and smooth UI/UX.',
      logoOne: {
        src: current729Logo,
        alt: '729 Logo',
      },
      logoTwo: {
        src: '',
        alt: '',
      },
    },
  ]

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title='Company History'
        description='We are software engineers, developers, designers, project managers, and outside-of-the-box thinkers. A one-stop-shop, you might say, and proud of it.'
      >
        <meta property='article:modified_time' content='2021-07-21T18:38:49+00:00' />
        <meta name='twitter:label1' content='Est. reading time' />
        <meta name='twitter:data1' content='17 minutes' />
      </Seo>

      <section id='hero' className='bg-pattern-dotted' style={{ backgroundSize: '170px' }}>
        <div className='container'>
          <div className='row m-md-0'>
            <div className='col-12 d-flex align-items-center'>
              <span className='d-block bg-primary my-5 mr-4 mr-md-5 py-6 pl-3 pr-4'></span>
              <h1 className='mb-0'>Company History</h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='col-12'>
            <Timeline timeline={companyTimeline} />
          </div>
          <div className='d-flex mt-4' style={{ width: '90%' }}>
            <Link to='/about-us' className='ml-auto'>
              <span className='mr-3'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
              Return to "About"
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CompanyHistory
