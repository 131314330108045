import React from 'react'

const Timeline = ({ timeline }) => {
  return (
    <div className='timeline-container mt-6 py-5 py-lg-0'>
      <div className='timeline position-relative d-lg-flex justify-content-center'>
        {timeline.map((pointInTime) => (
          <div className='point-in-time position-relative w-100 mb-5 mb-lg-0 mr-lg-5 pt-lg-5' key={pointInTime.id}>
            <div className='content text-lg-center'>
              <span className='year'>{pointInTime.year}</span>
              {console.log(pointInTime.content)}
              <p>{pointInTime.content}</p>
              {pointInTime.logoOne.src && <img src={pointInTime.logoOne.src} alt={pointInTime.logoOne.alt} className='my-4' />}
              {pointInTime.logoTwo.src && <img src={pointInTime.logoTwo.src} alt={pointInTime.logoTwo.alt} className='my-4' />}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Timeline
